<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="SettingsIcon" />
          <span>Servis Randevusu</span>
        </template>
        <service-form />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer />
      </b-tab>
    </b-tabs>
    <add-car :refresh-data="getCars" />
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import Customer from '@/views/Crm/View/Customer.vue'
import ServiceForm from '@/views/Service/Edit/ServiceForm.vue'
import AddCar from '@/views/Service/components/AddCar.vue'

export default {
  name: 'Edit',
  components: {
    BTabs,
    BTab,
    Customer,
    ServiceForm,
    AddCar,
  },
  created() {
    this.getServiceDetail()
    this.getCars()
  },
  methods: {
    getServiceDetail() {
      this.$store.dispatch('serviceTracing/serviceView', this.$route.params.id)
        .then(res => {
          if (res.id_com_cars) {
            this.$store.dispatch('cars/carView', res.id_com_cars)
          }
          if (res.id_com_customer) {
            this.$store.dispatch('customers/customerView', res.id_com_customer)
            this.getCars(res.id_com_customer)
          }
          if (res.id) {
            this.getMeets()
          }
        })
    },
    getCars(id) {
      this.$store.dispatch('cars/carsList', {
        where: {
          'com_cars.id_com_customer': id,
        },
      })
    },
    getMeets() {
      this.$store.dispatch('meets/meetsList', {
        select: [
          'com_meet.id AS id',
          'com_meet.title AS title',
        ],
      })
    },
  },
}
</script>
